/*
**
** @Description: 登录页面
** @Path: '/'
*/
<template>
  <div class="pl30 pr30">
    <div class="title fBlod">
      上牌
    </div>
    <van-form ref="forms" @submit="onSubmit">
      <div class="pl85 pr85 pt100">
        <div>
          <van-field
            v-model="formData.username"
            name="username"
            placeholder="请输入身份证"
            label="身份证"
            :rules="[
              { required: true, message: '请输入身份证' }
            ]"
          >
          </van-field>
        </div>
        <div>
          <van-field
            v-model="formData.username1"
            name="username"
            placeholder="请输入车牌号"
            label="车牌号"
            :rules="[
              { required: true, message: '请输入车牌号' }
            ]"
          >
          </van-field>
        </div>
        <div style="padding-top: 10vw;">
          <van-button block type="info" native-type="submit" class="main-btn fs34">
            提交
          </van-button>
        </div>
      </div>
    </van-form>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        checked: false,
        formData: {
          username1: '',
          username: ''
        }
      }
    },
    methods: {
      async onSubmit () {
        this.$toast.success('提交成功')
        this.$router.push('/')
      },
    }
  }
</script>

<style lang="less" scoped>
  .title{
    padding-top: 10vw;
    font-size: 8vw;
    padding-bottom: 5vw;
  }
  .leftIcon {
    width: 3.33vw;
  }
  /deep/ .van-cell {
    border-bottom: 1px solid #eee;
    padding-left: 5.33vw;
    padding-right: 5.33vw;
  }
  .main-btn {
    background: dodgerblue;
    border-color: dodgerblue;
  }
</style>
